"use client";
import React from "react";
import { CardHeader } from "@mui/material";
import styled from "@mui/material/styles/styled";

export const CardHeaderEllipsis = styled((props: any) => (
  <CardHeader {...props} />
))`
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 70%;
  }
`;
